import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';

export class TreeNodeModel {
  children?: TreeNodeModel[];
  title?: string;
  value?: any;
  isWarning?: boolean;
  expanded?: boolean;
  parentId?:string;
}

/**
 * @title Tree with flat nodes
 */
@Component({
  selector: 'jaro-kit-tree-node-component',
  templateUrl: 'tree-node.component.html',
  styleUrls: ['tree-node.component.scss'],
})
export class TreeNodeComponent implements OnInit {
  @Input() treeNodeItems: TreeNodeModel[];
  @Output() onClickNode = new EventEmitter<TreeNodeModel>();

  nestedTreeControl: NestedTreeControl<TreeNodeModel>;
  nestedDataSource: MatTreeNestedDataSource<TreeNodeModel>;

  constructor() {
    this.nestedTreeControl = new NestedTreeControl<TreeNodeModel>(this._getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();
  }

  ngOnInit(): void {
    this.nestedDataSource.data = this.treeNodeItems;
    this.nestedTreeControl.expandAll();
  }

  private _getChildren = (node: TreeNodeModel) => node.children

  hasNestedChild = (_: number, node: TreeNodeModel) => !!node.children && node.children?.length > 0;

  changeState(node: TreeNodeModel) {
    node.expanded = !node.expanded;
  }

  clickNode(node: TreeNodeModel){
    this.onClickNode.emit(node);
  }
}
